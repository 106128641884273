import {useMemo, useState} from "react";

import {useCache} from "./useCache";

export enum QueryStatus {
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
  ERROR = "ERROR",
  WAITING = "WAITING",
}

export type QueryState<T> = {
  data: T;
  status: QueryStatus;
  isSettled: boolean;
  isInitiated: boolean;
  isLoading: boolean;
};

const getQueryStatusProperties = (status: QueryStatus) => ({
  status,
  isInitiated: status !== QueryStatus.WAITING,
  isSettled: status === QueryStatus.SUCCESS || status === QueryStatus.ERROR,
  isLoading: status === QueryStatus.LOADING,
});

/**
 * Provides life-cycle updates and a small caching layer for an async data fetch.
 * @param fn async data fetch function
 * @param ifRejectedValue value for `data` if the async fn rejects
 * @param initialValue value for `data` before the first call to `fn` completes.
 * @param skip if true, `fn` will not execute
 * @param cacheKey serialized function arguments for lookup to prevent unnecessary calls to `fn`
 */
export const useQueryController = <T>({
  fn,
  initialValue,
  ifRejectedValue = initialValue,
  skip = false,
  cacheKey,
}: {
  fn: () => Promise<T>;
  cacheKey: string | number;
  ifRejectedValue?: T;
  initialValue: T;
  skip?: boolean;
}): QueryState<T> => {
  const {getCachedValue, updateCache} = useCache<T>();
  const [state, setState] = useState<QueryState<T>>({
    data: initialValue,
    ...getQueryStatusProperties(QueryStatus.WAITING),
  });

  const cached = useMemo(() => {
    const cacheCheck = getCachedValue(cacheKey);
    return cacheCheck.isHit
      ? {
          ...getQueryStatusProperties(QueryStatus.SUCCESS),
          data: cacheCheck.value,
        }
      : null;
  }, [cacheKey, getCachedValue]);

  const loading = useMemo(() => {
    return {
      ...state,
      ...getQueryStatusProperties(QueryStatus.LOADING),
    };
  }, [state]);

  const rejected = useMemo(() => {
    return {
      ...getQueryStatusProperties(QueryStatus.ERROR),
      data: ifRejectedValue,
    };
  }, [ifRejectedValue]);

  if (skip || state.status === QueryStatus.LOADING) {
    return state;
  } else if (cached) {
    return cached;
  } else {
    fn()
      .then(res => {
        updateCache(cacheKey, res);
        setState({
          ...getQueryStatusProperties(QueryStatus.SUCCESS),
          data: res,
        });
      })
      .catch(() => {
        setState(rejected);
      });

    setState(loading);
    return loading;
  }
};

export const mergeQueryLifecycleState = (...queries: QueryState<unknown>[]) => {
  const status = queries.every(query => query.status === QueryStatus.WAITING)
    ? QueryStatus.WAITING
    : queries.every(
        query => query.status === QueryStatus.SUCCESS || query.status === QueryStatus.WAITING,
      )
    ? QueryStatus.SUCCESS
    : queries.some(query => query.status === QueryStatus.ERROR)
    ? QueryStatus.ERROR
    : QueryStatus.LOADING;

  return {
    status,
    isInitiated: status !== QueryStatus.WAITING,
    isSettled: status === QueryStatus.SUCCESS || status === QueryStatus.ERROR,
    isLoading: status === QueryStatus.LOADING,
  };
};
